import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {ElLoading} from 'element-plus';
import {checkLogin} from "@/lib/access";
import Parameter from "@/views/Parameter.vue";

let loadingInstance: any;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/layout/ControlLayout.vue'),
        children: [
            {
                path: '',
                name: 'index',
                component: () => import('@/views/IndexView.vue'),
            },
            {
                path: 'creditsConfig',
                name: 'creditsConfig',
                component: () => import('@/views/CreditsConfig.vue'),
            },
            {
                path: 'creditsOrder',
                name: 'creditsOrder',
                component: () => import('@/views/CreditsOrder.vue'),
            },
            {
                path: 'creditTransactions',
                name: 'creditTransactions',
                component: () => import('@/views/CreditTransactions.vue'),
            },
            {
                path: 'newFileService',
                name: 'newFileService',
                component: () => import('@/views/NewFileService.vue'),
            },
            {
                path: 'fileServices',
                name: 'fileServices',
                component: () => import('@/views/FileServices.vue'),
            },
            {
                path: 'supportTickets',
                name: 'supportTickets',
                component: () => import('@/views/SupportTickets.vue'),
            },
            {
                path: 'supportTicketDetail',
                name: 'supportTicketDetail',
                component: () => import('@/views/SupportTicketDetail.vue'),
            },
            {
                path: 'openSupportTicket',
                name: 'openSupportTicket',
                component: () => import('@/views/OpenSupportTicket.vue'),
            },
            {
                path: 'setting',
                name: 'setting',
                component: () => import('@/views/Setting.vue'),
            },
            {
                path: 'users',
                name: 'users',
                component: () => import('@/views/Users.vue'),
            },
            {
                path: 'gearbox',
                name: 'gearbox',
                component: () => import('@/views/Gearbox.vue'),
            },
            {
                path: 'readMethod',
                name: 'readMethod',
                component: () => import('@/views/ReadMethod.vue'),
            },
            {
                path: 'carts',
                name: 'carts',
                component: () => import('@/views/Carts.vue'),
            },
            {
                path: 'addCart',
                name: 'addCart',
                component: () => import('@/views/AddCart.vue'),
            },
            {
                path: 'creditsDetail',
                name: 'creditsDetail',
                component: () => import('@/views/CreditsDetail.vue'),
            },
            {
                path: 'email',
                name: 'email',
                component: () => import('@/views/Email.vue'),
            },
            {
                path: 'time',
                name: 'time',
                component: () => import('@/views/Time.vue'),
            },
            {
                path: 'term',
                name: 'term',
                component: () => import('@/views/Term.vue'),
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: () => import('@/views/Privacy.vue'),
            },
            {
                path: 'condition',
                name: 'condition',
                component: () => import('@/views/Condition.vue'),
            },
            {
                path: 'tuningType',
                name: 'tuningType',
                component: () => import('@/views/TuningType.vue'),
            },
            {
                path: 'modifiedParts',
                name: 'modifiedParts',
                component: () => import('@/views/ModifiedParts.vue'),
            },
            {
                path: 'tuningTypeDetail',
                name: 'tuningTypeDetail',
                component: () => import('@/views/TuningTypeDetail.vue'),
            },
            {
                path: 'parameter',
                name: 'parameter',
                component: () => import('@/views/Parameter.vue'),
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/views/About.vue'),
            },
            {
                path: 'case',
                name: 'case',
                component: () => import('@/views/Case.vue'),
            },
            {
                path: 'case/detail',
                name: 'caseDetail',
                component: () => import('@/views/CaseDetail.vue'),
            },
            {
                path: 'contacts',
                name: 'contacts',
                component: () => import('@/views/Emails.vue'),
            }
        ]
    },
    {
        path: '/access',
        component: () => import('@/components/layout/DefaultLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/LoginView.vue'),
            },
            // {
            //     path: 'register',
            //     name: 'register',
            //     component: () => import('@/views/RegisterView.vue'),
            // },
            // {
            //     path: 'forgot',
            //     name: 'forgotPassword',
            //     component: () => import('@/views/ForgotPassword.vue'),
            // },
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (loadingInstance && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
    loadingInstance = ElLoading.service({
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.7)',
    });
    if (to.name != 'login' && to.name != 'register' && to.name != 'forgotPassword' && !checkLogin()) {
        next({name: 'login', query: {redirect: encodeURIComponent(from.fullPath)}});
    } else if ((to.name == 'login' || to.name == 'register' || to.name == 'forgotPassword') && checkLogin()) {
        next({name: 'index'});
    } else {
        next();
    }
})

router.afterEach((to, from) => {
    if (loadingInstance && loadingInstance.hasOwnProperty('close') && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
})
export default router
